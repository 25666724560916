import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CustomCard from "../../components/Cards/CustomCard";
import CategoryHead from "../../components/Category/CategoryHead";
import Slider from "../../components/Slider/Slider";
import ProductsTable from "../../components/Tables/ProductsTable";
import { useMainContext } from "../../context/MainContext";

const Home = () => {
  const { categories, popularRes, popularResIsLoading } = useMainContext();
  useEffect(() => {
    document.title = "مباشر";
  }, []);
  return (
    <>
      <Box>
        <Slider isLoading={popularResIsLoading} populars={popularRes} />
      </Box>
      {categories.map((category, index) => (
        <Box key={index}>
          <CategoryHead
            arTitle={category?.arName}
            enTitle={category?.enName}
            to={`${category?.enName?.toLowerCase()}`}
            text={"عرض"}
          />

          <ProductsTable
            maxHeightLg={"470px"}
            maxHeightMd={"350px"}
            rows={category?.response?.data}
            isLoading={category?.responseIsLoading}
          />
          <Box
            sx={{
              display: { md: "none" },
              height: "500px",
              overflow: "auto",
            }}
          >
            {category?.response?.data?.map((item, index) => (
              <CustomCard
                productName={item?.name}
                quantity={item.quantity}
                priceBefore={item?.price_before}
                priceAfter={item?.price_after}
                unit={item?.unit}
                key={index}
                mb={"24px"}
                isLoading={category?.responseIsLoading}
              />
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default Home;
