import axios from "axios";

const getProducts = async (
  setResponse,
  setIsLoading,
  categoryId,
  page = 1,
  searchByName = ""
) => {
  setIsLoading(true);
  try {
    const response = await axios.get(
      `category-products?category_id=${categoryId}&page=${page}&name=${searchByName}`
    );
    await setResponse(response?.data?.data);
    setIsLoading(false);
    return;
  } catch (error) {
    setIsLoading(false);
  }
};
export default getProducts;
