import axios from "axios";

const getPopular = async (setResponse, setIsLoading) => {
  setIsLoading(true);
  try {
    const response = await axios.get(`menu-active-products`);
    await setResponse(response?.data?.data);
    setIsLoading(false);
    return;
  } catch (error) {
    setIsLoading(false);
  }
};
export default getPopular;
