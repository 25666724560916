import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import DataLoading from "../Loading/DataLoading";

const ProductsTable = ({ rows, maxHeightMd, maxHeightLg, isLoading }) => {
  const NumUnit = ({ number, unit }) => {
    return (
      <Box>
        <Typography
          variant="caption"
          sx={{
            color: "primary.main",
            fontSize: { md: "18px", lg: "24px" },
            fontWeight: "bold",
            marginRight: { xs: "4px", md: "8px" },
          }}
        >
          {number}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "primary.main", fontSize: { md: "18px", lg: "24px" } }}
        >
          {unit}
        </Typography>
      </Box>
    );
  };
  const PriceCells = ({ priceCase, number, unit }) => {
    return (
      <Box sx={{ display: "flex", gap: { md: "8px", lg: "16px" } }}>
        <Typography
          variant="h5"
          component={"p"}
          sx={{
            fontSize: { md: "18px", lg: "28px" },
            color: "secondary.main",
          }}
        >
          {priceCase}
        </Typography>
        <NumUnit number={number} unit={unit} />
      </Box>
    );
  };
  const styleTableHeadCell = {
    color: "white",
    fontSize: { md: "22px", lg: "28px" },
    textAlign: "center",
    bgcolor: "primary.main",
  };
  const styleBodyTableRow = {
    height: { md: "60px", lg: "80px" },
    "&:nth-of-type(even)": {
      backgroundColor: "custom.tableBgColor",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  };
  return (
    <TableContainer
      sx={{
        display: { md: "block", xs: "none" },
        maxHeight: { md: maxHeightMd, lg: maxHeightLg },
      }}
    >
      <Table stickyHeader sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow
            sx={{
              height: { md: "48px", lg: "68px" },
            }}
          >
            <TableCell
              sx={styleTableHeadCell}
              style={{ borderRadius: "0 30px 30px  0" }}
            ></TableCell>
            <TableCell sx={styleTableHeadCell}>المنتج</TableCell>
            <TableCell sx={styleTableHeadCell}>السعر</TableCell>
            <TableCell
              sx={styleTableHeadCell}
              style={{ borderRadius: "30px 0 0 30px" }}
            >
              الكمية
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow sx={styleBodyTableRow} key={row?.id}>
              <TableCell
                sx={{
                  fontSize: { md: "18px", lg: "28px" },
                  fontWeight: "bold",
                  color: "secondary.main",
                  textAlign: "center",
                  width: "4%",
                }}
              >
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: { md: "24px", lg: "32px" },
                  fontWeight: "bold",
                  color: "primary.main",
                  textAlign: "center",
                  width: "30%",
                }}
              >
                {row?.name}
              </TableCell>
              <TableCell sx={{ width: "42%" }}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  divider={<Divider orientation="vertical" flexItem />}
                  justifyContent={"center"}
                >
                  <PriceCells
                    number={row?.price_after}
                    unit={"ريال"}
                    priceCase={"قبل"}
                  />
                  <PriceCells
                    number={row?.price_before}
                    unit={"ريال"}
                    priceCase={"بعد"}
                  />
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: { md: "24px", lg: "32px" },
                  fontWeight: "bold",
                  color: "primary.main",
                  textAlign: "center",
                  width: "30%",
                }}
              >
                <NumUnit number={row?.quantity} unit={row?.unit} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DataLoading isLoading={isLoading} />
    </TableContainer>
  );
};

export default ProductsTable;
