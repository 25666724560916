import { Box, Typography } from "@mui/material";
import React from "react";
import MainButton from "../Buttons/MainButton";
import SearchTextInput from "../Inputs/SearchTextInput";

const CategoryHead = ({
  arTitle,
  enTitle,
  to,
  text,
  defaultValue,
  onChange,
  onClick,
  searchIsVisible,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          py: "32px",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "36px", lg: "52px" },
              color: "secondary.main",
            }}
          >
            {arTitle}
          </Typography>
          <Typography
            variant="h3"
            component={"h2"}
            sx={{
              fontSize: { md: "14px", lg: "16px" },
              color: "secondary.main",
            }}
          >
            {enTitle}
          </Typography>
        </Box>
        <SearchTextInput
          display={{ xs: "none", sm: "block" }}
          defaultValue={defaultValue}
          onChange={onChange}
          onClick={onClick}
          searchIsVisible={searchIsVisible}
        />
        <MainButton to={to} text={text} />
      </Box>
      <Box sx={{ pb: "24px", display: "flex", justifyContent: "center" }}>
        <SearchTextInput
          display={{ xs: "block", sm: "none" }}
          defaultValue={defaultValue}
          onChange={onChange}
          onClick={onClick}
          searchIsVisible={searchIsVisible}
        />
      </Box>
    </>
  );
};

export default CategoryHead;
