import { createTheme } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
// import mainTheme from "../assets/themes/mainTheme";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { useLocation } from "react-router-dom";

const MainThemeContext = createContext();
const MainThemeProvider = ({ children }) => {
  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const dir = "rtl";

  const [mode, setMode] = useState("light");
  const theme = React.useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            container: 1920,
          },
        },
        direction: dir,
        palette: {
          mode: mode,
          primary: {
            main: "#3aaa35",
          },
          secondary: {
            main: "#707070",
          },
          custom: {
            tableBgColor: "#F1F1F1",
          },
        },

        typography: {
          h2: {
            fontWeight: "bold",
            fontSize: 28,
          },
          h3: {
            fontWeight: "bold",
            fontSize: 10,
          },
          h4: {
            fontWeight: 500,
            fontSize: 14,
          },
          h5: {
            fontSize: 12,
            fontWeight: 400,
          },
          h6: {
            fontSize: 12,
            fontWeight: "bold",
          },
          body1: {
            fontWeight: 400,
            fontSize: 10,
          },
          body2: {
            fontSize: 14,
            fontWeight: "bold",
          },
          caption: {
            fontSize: 10,
            fontWeight: 400,
          },
          caption2: {
            fontSize: 8,
            fontWeight: 600,
          },

          button: {
            textTransform: "none",
            fontSize: 18,
            fontWeight: "bold",
          },
          fontFamily: "Cairo , sans-serif",
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                padding: 0,
                borderRadius: 25,
                width: "83.2px",
                height: "33px",
              },
            },
          },
        },
      }),
    [mode]
  );

  // const colorMode = React.useMemo(
  //   () => ({
  //     toggleColorMode: () => {
  //       setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  //     },
  //   }),
  //   [theme]
  // );

  return (
    <MainThemeContext.Provider
      value={{
        dir,
        cacheLtr,
        cacheRtl,
        theme,
      }}
    >
      {children}
    </MainThemeContext.Provider>
  );
};

export default MainThemeProvider;
export const useMainThemeContext = () => {
  return useContext(MainThemeContext);
};
