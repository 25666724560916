import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import getInformation from "../apis/getInformation";
import getPopular from "../apis/getPopular";
import getProducts from "../apis/getProducts";

const MainContext = createContext();

const MainProvider = ({ children }) => {
  axios.defaults.baseURL = "https://mobasher.almaharh.com/api/";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["lang"] = "ar";
  ///////////////////////////////////////////////////
  const [informationRes, setInformationRes] = useState({});

  const [vegetablesRes, setVegetablesRes] = useState({});
  const [vegetablesResIsLoading, setVegetablesResIsLoading] = useState(false);
  const [fruitsRes, setFruitsRes] = useState({});
  const [fruitsResIsLoading, setFruitsResIsLoading] = useState(false);
  const [leavesRes, setLeavesRes] = useState({});
  const [leavesResIsLoading, setLeavesResIsLoading] = useState(false);
  const [popularRes, setPopularRes] = useState([]);
  const [popularResIsLoading, setPopularResIsLoading] = useState(false);

  const categories = [
    {
      id: 1,
      arName: "الخضار",
      enName: "Vegetables",
      setResponse: setVegetablesRes,
      setIsLoading: setVegetablesResIsLoading,
      response: vegetablesRes,
      responseIsLoading: vegetablesResIsLoading,
    },
    {
      id: 2,
      arName: "الفاكهة",
      enName: "Fruits",
      setResponse: setFruitsRes,
      setIsLoading: setFruitsResIsLoading,
      response: fruitsRes,
      responseIsLoading: fruitsResIsLoading,
    },
    {
      id: 3,
      arName: "ورقيات",
      enName: "Leaves",
      setResponse: setLeavesRes,
      setIsLoading: setLeavesResIsLoading,
      response: leavesRes,
      responseIsLoading: leavesResIsLoading,
    },
  ];

  useEffect(() => {
    categories.map((category) => {
      getProducts(category?.setResponse, category?.setIsLoading, category?.id);
    });
    getInformation(setInformationRes);
    getPopular(setPopularRes, setPopularResIsLoading);
  }, []);

  return (
    <MainContext.Provider
      value={{ categories, informationRes, popularRes, popularResIsLoading }}
    >
      {children}
    </MainContext.Provider>
  );
};
// make sure use
export default MainProvider;
export const useMainContext = () => {
  return useContext(MainContext);
};
