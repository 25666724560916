import axios from "axios";
const getInformation = async (setResponse) => {
  try {
    const response = await axios.get("information");
    return setResponse(response?.data?.data);
  } catch (error) {
    return;
  }
};

export default getInformation;
