import { IconButton } from "@mui/material";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useMainContext } from "../../context/MainContext";
const WhatsAppBtn = () => {
  const { informationRes } = useMainContext();
  return (
    <IconButton
      color="primary"
      component={"a"}
      target="_blank"
      href={informationRes?.whatsapp}
      sx={{
        position: "fixed",
        bottom: "60px",
        zIndex: 1200,
        boxShadow: " 10px 10px 48px rgba(0,0,0,.161)",
        bgcolor: "white",
        width: { xs: "50px", md: "75px" },
        height: { xs: "50px", md: "75px" },
        ":hover": {
          bgcolor: "white",
        },
      }}
    >
      <WhatsAppIcon sx={{ fontSize: "48px" }} />
    </IconButton>
  );
};

export default WhatsAppBtn;
