import { Box } from "@mui/material";
import React from "react";
import logo from "../../assets/images/mubashirLogo.svg";
import { Link as RouterLink } from "react-router-dom";

const HeaderLogo = () => {
  return (
    <Box
      component={RouterLink}
      to="./"
      sx={{
        img: {
          width: { md: 140, xs: 97 },
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Box component={"img"} src={logo} alt="mubashir" />
    </Box>
  );
};

export default HeaderLogo;
