import { Skeleton } from "@mui/material";
import React from "react";

const DataLoading = ({ isLoading, width = "100%" }) => {
  return (
    <>
      {isLoading && (
        <>
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "10px",
              width: width,
              height: "60px",
              m: "auto",
              my: "12px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "10px",
              width: width,
              height: "60px",
              m: "auto",
              my: "12px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "10px",
              width: width,
              height: "60px",
              m: "auto",
              my: "12px",
            }}
          />
        </>
      )}
    </>
  );
};

export default DataLoading;
