import {
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import "./App.css";
import { useMainThemeContext } from "./context/MainThemeContext";
import { CacheProvider } from "@emotion/react";
import Header from "./components/Header/Header";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Notfound from "./pages/NotFound/NotFound";
import { useMainContext } from "./context/MainContext";
import Category from "./pages/Category/Category";
import WhatsAppBtn from "./components/Buttons/WhatsAppBtn";
import Populars from "./pages/Populars/Populars";

function App() {
  const { mode, theme, dir, cacheLtr, cacheRtl } = useMainThemeContext();
  const { categories } = useMainContext();

  return (
    <>
      <CacheProvider value={dir === "ltr" ? cacheLtr : cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <Typography component={"h1"} sx={{ display: "none" }}>
            مباشر
          </Typography>

          <Header />
          <Container
            sx={{
              height: "100%",
              px: { xs: "12px", md: "50px", lg: "62px" },
              pb: { xs: "30px", md: "90px", lg: "120px" },
            }}
            maxWidth="container"
          >
            <Routes>
              <Route path={"/"} element={<Home />}></Route>
              <Route path={"/home"} element={<Home />}></Route>
              {categories?.map((category, index) => (
                <Route
                  key={index}
                  path={`/${category?.enName?.toLowerCase()}`}
                  element={<Category category={category} />}
                ></Route>
              ))}
              <Route path="/populars" element={<Populars />}></Route>

              <Route path="/*" element={<Notfound />}></Route>
            </Routes>
            <WhatsAppBtn />
          </Container>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

export default App;
