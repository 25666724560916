import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import HeaderLogo from "./HeaderLogo";
import { useMainThemeContext } from "../../context/MainThemeContext";
import LiveTime from "../LiveTime/LiveTime";
import SearchTextInput from "../Inputs/SearchTextInput";
const Header = () => {
  return (
    <>
      <AppBar
        sx={{
          position: "static",
          boxShadow: 0,
          bgcolor: "transparent",
        }}
      >
        <Container
          sx={{
            p: { xs: "12px", md: "50px", lg: "62px" },
          }}
          maxWidth="container"
        >
          <Toolbar
            variant="dense"
            disableGutters
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <HeaderLogo />
            <LiveTime />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
