import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { Link as RouterLink } from "react-router-dom";
import CustomCard from "../Cards/CustomCard";
import { Box } from "@mui/material";

const Slider = ({ populars, isLoading }) => {
  return (
    <>
      <Box
        component={RouterLink}
        to={"/populars"}
        style={{ textDecoration: "none" }}
      >
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          slidesPerGroup={1}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            1200: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
          }}
        >
          {populars?.map((item, i) => (
            <SwiperSlide key={i}>
              <CustomCard
                isLoading={isLoading}
                productName={item?.name}
                quantity={item?.quantity}
                unit={item?.unit}
                priceAfter={item?.price_after}
                priceBefore={item?.price_before}
                mb={"48px"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
};

export default Slider;
