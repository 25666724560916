import React from "react";
import { Box, InputAdornment, TextField, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchTextInput = ({
  searchIsVisible,
  defaultValue,
  onChange,
  onClick,
  display,
}) => {
  const theme = useTheme();
  const mainColor = theme?.palette?.primary.main;
  return (
    <>
      {searchIsVisible && (
        <Box sx={{ display: display }}>
          <TextField
            placeholder="ابحث عن المنتجات"
            sx={{
              "& fieldset ": { borderColor: "transparent" },
              "&:hover fieldset": {
                borderColor: `${mainColor}` + "!important",
              },
            }}
            type="text"
            defaultValue={defaultValue}
            onChange={onChange}
            InputProps={{
              sx: {
                borderRadius: { xs: "25px", lg: "30px" },
                bgcolor: "custom.tableBgColor",
                height: { xs: "45px", lg: "60px" },
                width: { xs: "330px", lg: "600px" },
                fontSize: { xs: "14px", sm: "18px", md: "24px" },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    onClick={onClick}
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "24px", lg: "36px" },
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
};

export default SearchTextInput;
