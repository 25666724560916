import { Button } from "@mui/material";
import React from "react";
import { Link as routerLink } from "react-router-dom";

const MainButton = ({ to, text }) => {
  return (
    <Button
      component={routerLink}
      to={to}
      variant="contained"
      sx={{
        fontSize: { md: "24px", lg: "32px" },
        width: { md: "120px", lg: "156px" },
        height: { md: "52px", lg: "60px" },
      }}
    >
      {text}
    </Button>
  );
};

export default MainButton;
