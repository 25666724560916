import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";
const Notfound = () => {
  useEffect(() => {
    document.title = "هذه الصفحة غير موجودة";
  }, []);
  return (
    <Box>
      <Typography>هذه الصفحة غير موجودة</Typography>
    </Box>
  );
};

export default Notfound;
