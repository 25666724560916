import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import DataLoading from "../Loading/DataLoading";

const CustomCard = ({
  mb,
  isLoading,
  productName,
  quantity,
  priceBefore,
  priceAfter,
  unit,
}) => {
  const CardTitle = ({ children }) => {
    return (
      <>
        <Typography
          sx={{ fontSize: { md: "22px", lg: "28px" }, color: "secondary.main" }}
          variant="h6"
        >
          {children}
        </Typography>
      </>
    );
  };
  const CardCaption = ({ number, unit }) => {
    return (
      <Box
        sx={{
          display: "flex",
          rowGap: { xs: "4px", md: "8px" },
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "primary.main",
            fontSize: { md: "18px", lg: "24px" },
            fontWeight: "bold",
            marginRight: { xs: "4px", md: "8px" },
          }}
        >
          {number}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "primary.main", fontSize: { md: "18px", lg: "24px" } }}
        >
          {unit}
        </Typography>
      </Box>
    );
  };
  const CardSubTitle = ({ subTitle }) => {
    return (
      <>
        <Typography
          variant="h5"
          component={"p"}
          sx={{
            fontSize: { md: "18px", lg: "28px" },
            color: "secondary.main",
          }}
        >
          {subTitle}
        </Typography>
      </>
    );
  };
  return (
    <>
      <Card
        sx={{
          marginX: "auto",
          mb: mb,
          width: { xs: "340px", md: "560px", lg: "746px" },
          height: { xs: "80px", md: "130px", lg: "170px" },
          pt: { xs: "16px", md: "24px", lg: "32px" },
          px: { xs: "16px", md: "24px", lg: "38px" },
          borderRadius: { xs: "14px", md: "24px", lg: "30px" },
          boxShadow: {
            xs: "8px 8px 12px #00000014",
            md: "10px 10px 24px 00000022 ",
            lg: "12px 12px 40px #00000029",
          },
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Box width={"50%"}>
            <CardTitle>المنتج</CardTitle>
            <Typography
              variant="body2"
              sx={{
                color: "primary.main",
                fontSize: { md: "24px", lg: "32px" },
              }}
            >
              {productName}
            </Typography>
          </Box>
          <Box textAlign={"center"} width={"20%"}>
            <CardTitle>الكمية</CardTitle>
            <CardCaption number={quantity} unit={unit} />
          </Box>
          <Box width={"30%"} textAlign={"center"}>
            <CardTitle>السعر</CardTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <CardSubTitle subTitle={"قبل"} />
              <CardCaption number={priceAfter} unit={"ريال"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <CardSubTitle subTitle={"بعد"} />
              <CardCaption number={priceBefore} unit={"ريال"} />
            </Box>
          </Box>
        </Stack>
      </Card>
      <DataLoading isLoading={isLoading} width="80%" />
    </>
  );
};

export default CustomCard;
