import { Box, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getProducts from "../../apis/getProducts";
import CustomCard from "../../components/Cards/CustomCard";
import CategoryHead from "../../components/Category/CategoryHead";
import ProductsTable from "../../components/Tables/ProductsTable";

const Category = ({ category }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(true);
  const { id } = category;
  const [productsRes, SetroductsRes] = useState({});
  const [productsResisLoading, setProductsResisLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams?.get("name") || "");
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams?.get("page")) || 1
  );
  const handlePagination = (value) => {
    setCurrentPage(value);
    navigate({
      search: `page=${value}${searchText && `&name=${searchText}`}`,
    });
    getProducts(SetroductsRes, setProductsResisLoading, id, value, searchText);
  };
  const getSearchedProducts = () => {
    setCurrentPage(1);
    navigate({
      search: `name=${searchText}`,
    });
    getProducts(
      SetroductsRes,
      setProductsResisLoading,
      id,
      currentPage,
      searchText
    );
  };
  useEffect(() => {
    getProducts(
      SetroductsRes,
      setProductsResisLoading,
      id,
      currentPage,
      searchText
    );
  }, []);
  useEffect(() => {
    document.title = `مباشر - ${category?.arName}`;
  }, []);
  return (
    <Box>
      <CategoryHead
        arTitle={category?.arName}
        enTitle={category?.enName}
        to={"/"}
        text="رجوع"
        onChange={(event) => setSearchText(event.target.value)}
        defaultValue={searchText}
        onClick={() => getSearchedProducts()}
        searchIsVisible={isSearchVisible}
      />
      <ProductsTable
        maxHeightLg={"860px"}
        maxHeightMd={"735px"}
        rows={productsRes?.data}
        isLoading={productsResisLoading}
      />
      <Box
        sx={{
          display: { md: "none" },
          height: productsRes?.data?.length && "700px",
          overflow: "auto",
        }}
      >
        <CustomCard
          products={productsRes?.data}
          mb={"24px"}
          isLoading={productsResisLoading}
        />
      </Box>
      {!productsRes?.data?.length && !productsResisLoading && searchText && (
        <Typography
          variant="h4"
          component={"p"}
          sx={{
            textAlign: "center",
            fontSize: { md: "24px", lg: "32px" },
            color: "secondary.main",
            my: "16px",
          }}
        >
          لايوجد نتائج بحث
        </Typography>
      )}

      {productsRes?.pagination?.total_pages > 1 && (
        <Box sx={{ py: "32px", display: "flex", justifyContent: "center" }}>
          <Pagination
            count={productsRes?.pagination?.total_pages}
            color="primary"
            size="large"
            onChange={(e, value) => handlePagination(value)}
            page={currentPage}
          />
        </Box>
      )}
    </Box>
  );
};

export default Category;
