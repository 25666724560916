import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CustomCard from "../../components/Cards/CustomCard";
import CategoryHead from "../../components/Category/CategoryHead";
import { useMainContext } from "../../context/MainContext";

const Populars = () => {
  const { popularRes, popularResIsLoading } = useMainContext();
  useEffect(() => {
    document.title = "مباشر - المنتجات الرائجة";
  }, []);
  return (
    <>
      <>
        <CategoryHead to={"/"} text="رجوع" />
      </>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {popularRes?.map((item, index) => (
          <CustomCard
            productName={item?.name}
            quantity={item.quantity}
            priceBefore={item?.price_before}
            priceAfter={item?.price_after}
            unit={item?.unit}
            key={index}
            mb={"24px"}
            isLoading={popularResIsLoading}
          />
        ))}
      </Box>
    </>
  );
};

export default Populars;
