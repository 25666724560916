import React from "react";
import clock from "../../assets/images/clock.svg";
import calendar from "../../assets/images/calendar.svg";
import Clock from "react-live-clock";
import "./LiveTime.scss";

const LiveTime = () => {
  return (
    <div className="live-time">
      <div className="time-item">
        <img src={calendar} alt="" />
        <Clock format={"D/ M / Y"} ticking={true} className={"time-text"} />
      </div>
      <div className="time-item">
        <img src={clock} alt="" />
        <Clock format={"h:mm A"} ticking={true} className={"time-text"} />
      </div>
    </div>
  );
};

export default LiveTime;
